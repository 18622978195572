import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 22",
  week: "Semana 4",
  month: "abr",
  day: "04",
  monthNumber: "04",
  date: "2020-04-04",
  path: "/cronologia/semana-04#dia-04-abr",
};

const Day22 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-04"} />
        <ModText>
          El Ministerio de Transición Ecológica y Reto Demográfico ha impulsado
          <a
            href="https://www.miteco.gob.es/es/ministerio/medidas-covid19/"
            target="_blank" 
 rel="noopener noreferrer"
          >
            medidas adicionales
          </a>
          para proteger a ciudadanos y empresas, con el objetivo de{" "}
          <strong>garantizar los suministros básicos en los hogares</strong>.
          Estas medidas han sido recogidas en el Real Decreto-ley de 31 de
          marzo, por el que se adoptan medidas urgentes complementarias en el
          ámbito social y económico para hacer frente al coronavirus.
        </ModText>
        <ModAnimation svg="/images/anim/04_abr_suministros_basicos.svg" />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 124.736
          casos (7.026 más que el día anterior), 11.744 fallecidos (9,4% de los
          casos positivos) y 34.219 curados (27,4% de los casos positivos). El
          total de personas en UCI asciende a 6.532.
        </ModText>
        <ModText>
          Siguiendo las recomendaciones de los expertos y científicos, el
          Gobierno ha solicitado la{" "}
          <strong>prórroga del estado de alarma</strong> por COVID-19 durante
          dos semanas más: desde el 12 de abril{" "}
          <strong>hasta el sábado 25 de abril</strong>.
        </ModText>
        <ModTwoCols
          src="/images/svg/04_abr_militares.svg"
          alt="más de 8.000 militares están desplegados en 271 localidades"
          small={true}
        >
          Mientras tanto,{" "}
          <strong>
            más de 8.000 militares están desplegados en 271 localidades
          </strong>{" "}
          con tareas de presencia, desinfección y apoyo a las capacidades
          hospitalarias, traslado de pacientes, personas mayores y fallecidos
          por COVID-19.
        </ModTwoCols>
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación, por
          su parte, ha realizado distintas{" "}
          <strong>
            gestiones con Turquía para la obtención de material sanitario
          </strong>{" "}
          necesario para hacer frente a la crisis a la que nos estamos
          enfrentando.
        </ModText>
        <ModTwoCols
          src="/images/svg/04_abr_china_mascarillas.svg"
          alt="llega desde China un primer avión con 2,5 millones de mascarillas"
          small={true}
        >
          Asimismo,{" "}
          <strong>
            llega desde China un primer avión con 2,5 millones de mascarillas
          </strong>{" "}
          para las CCAA e instituciones públicas. El trayecto ha sido asumido
          por Alpitour World como muestra de solidaridad entre dos países
          (España e Italia) que se enfrentan al coronavirus.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/04_abr_guia_deportes.svg"
          alt="guías con preguntas frecuentes"
          small={true}
        >
          El Ministerio de Cultura y Deporte también ha publicado dos guías con
          preguntas frecuentes sobre la
          <a
            href="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/cultura/Documents/2020/030420-FAQAutonomos.pdf"
            target="_blank" 
 rel="noopener noreferrer"
          >
            prestación extraordinaria para autónomos
          </a>
          y
          <a
            href="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/cultura/Documents/2020/030420-FAQERTE.pdf"
            target="_blank" 
 rel="noopener noreferrer"
          >
            ERTEs
          </a>
          en el ámbito cultural.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/04_abr_violencia_genero.svg"
          alt="violencia de género"
          small={false}
          inverted={true}
        >
          <strong>
            Los servicios de atención a las víctimas de violencia género han
            sido declarados esenciales
          </strong>
          , y su funcionamiento estará garantizado. Para ello, el Gobierno ha
          elaborado una
          <a
            href="http://www.violenciagenero.igualdad.gob.es/informacionUtil/covid19/GuiaVictimasVGCovid19.pdf"
            target="_blank" 
 rel="noopener noreferrer"
          >
            guía de actuación para mujeres que estén sufriendo violencia de
            género
          </a>
          en situación de permanencia domiciliaria, donde incluyen todos los
          recursos disponibles y cómo usarlos.
        </ModTwoCols>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.miteco.gob.es/es/ministerio/medidas-covid19/"
            name="Medidas impulsadas por el Ministerio de Transición
            Ecológica y Reto Demográfico"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/cultura/Documents/2020/030420-FAQERTE.pdf"
            name="Guía de preguntas frecuentes del Ministerio de Cultura y
            Deporte - ERTEs"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/cultura/Documents/2020/030420-FAQAutonomos.pdf"
            name="Guía de preguntas frecuentes del Ministerio de Cultura y
            Deporte - Autónomos"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day22;
