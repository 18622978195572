import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 25",
  week: "Semana 4",
  month: "abr",
  day: "07",
  monthNumber: "04",
  date: "2020-04-07",
  path: "/cronologia/semana-04#dia-07-abr",
};

const Day25 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModTwoCols
          src="/images/svg/0704-computer-lab.svg"
          alt="seis nuevos ensayos para mejorar la prevención"
          small={false}
        >
          Asimismo, el Instituto de Salud Carlos III ha financiado{" "}
          <strong>
            seis nuevos ensayos para mejorar la prevención, diagnóstico y
            tratamiento del COVID-19
          </strong>
          . Los proyectos evaluarán tratamientos antivirales, test diagnósticos
          con nanotecnología, reposicionamiento de fármacos utilizados en otras
          indicaciones y prevención farmacológica en personas sin infección.
        </ModTwoCols>
        <ModText>
          En cuanto a la operación de retorno de viajeros a España, ya son{" "}
          <strong>
            27 vuelos los que han permitido el regreso de españoles
          </strong>
          hasta el inicio de esta semana, y a los que habrá que sumar en los
          próximos días cinco vuelos más.
        </ModText>
        <ModText>
          La mayoría de los vuelos son operados por aerolíneas comerciales de
          bandera española, con precios establecidos por las propias compañías.
          En algunos casos, el Ministerio de Asuntos Exteriores, Unión Europea y
          Cooperación ha fletado aviones con el compromiso de reembolso por cada
          pasajero.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-07"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 5.478 nuevos positivos y 637 personas fallecidas.
          Permanecen hospitalizadas 59.662 personas, 918 más que el día
          anterior, y 2.357 reciben el alta. El número de casos ingresados en la
          UCI asciende a 6.931, lo que supone un aumento de 70 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 9,7 % y la tasa de recuperados del 29,9 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/0704-digital-health.svg"
          alt="test rápidos entre las comunidades autónomas"
          small={false}
        >
          El Ministerio de Sanidad continúa{" "}
          <strong>
            distribuyendo test rápidos entre las comunidades autónomas
          </strong>
          con el propósito de aumentar las posibilidades de diagnóstico precoz.
          Además del millón de test que comenzó a distribuir el pasado domingo,
          suministrará cuatro millones más en los próximos días. Para realizar
          un uso correcto de los mismos, ha publicado en su web una{" "}
          <strong>guía para la utilización de test rápidos.</strong>
        </ModTwoCols>
        <ModText>
          El ministerio también llevará a cabo un{" "}
          <strong>
            estudio para conocer el grado de expansión de COVID-19
          </strong>
          . Durante tres semanas, se realizarán test a 60.000 personas escogidas
          de forma aleatoria.
        </ModText>
        <ModTwoCols src="/images/svg/0704-masks.svg" alt="mascaras" small={true}>
          Gracias a la Embajada de China en España y a la empresa YXE, hoy ha
          llegado a Madrid un tren de la línea Yiwu-Madrid, la más larga del
          mundo, cargado con material sanitario: 110.000 mascarillas y 766
          trajes de protección.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/0704-piggybank.svg"
          alt="hucha"
          small={true}
          inverted={true}
        >
          El Gobierno, por su parte, ha garantizado a todos los mutualistas,
          tanto de la Mutualidad General de Funcionarios Civiles del Estado
          (MUFACE) como de la Mutualidad General Judicial (MUGEJU), que
          comiencen o continúen una situación de incapacidad temporal, la
          percepción de sus retribuciones durante la vigencia del estado de
          alarma.
        </ModTwoCols>
        <ModText>
          El Ministerio de Transportes, Movilidad y Agenda Urbana ha establecido
          una serie de medidas excepcionales para titulares de tarjetas de
          tacógrafo, con la finalidad de facilitar la labor a estos
          transportistas profesionales. Todo conductor que tenga caducada la
          tarjeta de tacógrafo desde el día 6 de marzo, y al que no le haya sido
          entregada la tarjeta renovada, podrá seguir realizando transporte
          imprimiendo las actividades.
        </ModText>
        <ModImage src="/images/svg/0704-delyvery-truck.svg" alt="camión de reparto" />
        <ModTwoCols
          src="/images/svg/0704-cruise-on-port.svg"
          alt="retorno de turistas"
          small={false}
        >
          Por otra parte, también se ha gestionado el retorno de un amplio grupo
          de turistas españoles y tripulantes de diferentes cruceros, que han
          desembarcado en Italia y vuelven a España en diferentes autobuses.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day25;
