import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 27",
  week: "Semana 4",
  month: "abr",
  day: "09",
  monthNumber: "04",
  date: "2020-04-09",
  path: "/cronologia/semana-04#dia-09-abr",
};

const Day27 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-09"} />
        <ModTwoCols
          src="/images/svg/09_abr_congreso.svg"
          alt="autorizado la segunda prórroga del estado de alarma"
          small={false}
        >
          Con la finalidad de frenar la expansión de COVID-19, el Congreso ha{" "}
          <strong>autorizado la segunda prórroga del estado de alarma</strong>,
          hasta el 26 abril, para hacer frente a la crisis sanitaria.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/09_abr_decretos_leyes.svg"
          alt="dos reales decretos leyes para hacer frente a la crisis del COVID-19"
          small={false}
        >
          En el ámbito laboral, el Congreso ha convalidado{" "}
          <strong>
            dos reales decretos leyes para hacer frente a la crisis del COVID-19
          </strong>
          . En concreto, se han sometido a debate y aprobación los reales
          decretos leyes 9/2020 y 10/2020.
        </ModTwoCols>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 5.756 nuevos positivos y 757 personas fallecidas.
          Permanecen hospitalizadas 63.516 personas, 423 más que el día
          anterior, y 4.813 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.132, lo que supone un aumento de 63 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10% y la tasa de recuperados del 34,2%.
        </ModText>
        <ModImage src="/images/svg/09_abr_une.svg" alt="Especificación UNE 0064-1" />
        <ModText>
          La Asociación Española de Normalización (UNE) ha publicado la{" "}
          <strong>Especificación UNE 0064-1</strong>, en la que establece los
          requisitos mínimos que deben cumplir las mascarillas higiénicas en
          cuanto a los materiales que se utilizan para su elaboración,
          confección, marcado y uso.
        </ModText>
        <ModText>
          Se trata de una Especificación clave para facilitar la fabricación
          masiva de mascarillas, poniéndose a disposición gratuita de toda la
          Sociedad. Pero dicha especificación no aplica a las mascarillas
          quirúrgicas ni a las medias máscaras filtrantes de protección contra
          partículas, que deben seguir sus correspondientes requisitos.
        </ModText>
        <ModText>
          La velocidad de duplicación, usada para evaluar la rapidez ala que se
          transmite de la enfermedad, se ha ampliado siendo necesarios 30 días
          para duplicar el número de casos frente a los 14 días de hace una
          semana.
        </ModText>
        <ModGraph
          name="velocidadDuplicacion-MD-0904"
          alt="graficas coronavirus duplicacion madrid"
        />
        <ModText>
          Por último, <strong>ONU Mujeres</strong> se ha reunido con el
          Ministerio de Igualdad para apoyar y reconocer el trabajo que se está
          haciendo en materia de protección a las víctimas de violencia de
          género en el contexto de COVID-19, y califican dichas medidas como un
          referente internacional para el resto de países.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day27;
