import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 24",
  week: "Semana 4",
  month: "abr",
  day: "06",
  monthNumber: "04",
  date: "2020-04-06",
  path: "/cronologia/semana-04#dia-06-abr",
};

const Day24 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-06"} />
        <ModTwoCols
          src="/images/svg/06_abr_app.svg"
          alt="aplicación oficial de autodiagnóstico AsistenciaCOVID-19"
          small={false}
        >
          Por otra parte, otra de las herramientas que van a ayudar a
          descongestionar el sistema, y con ello, frenar el número de contagios,
          es la
          <a href="https://asistencia.covid19.gob.es/" target="_blank" rel="noopener noreferrer">
            aplicación oficial de autodiagnóstico AsistenciaCOVID-19
          </a>
          . Hasta el momento estaba disponible en Madrid, donde comenzó el
          proyecto piloto, y desde hoy, también está disponible en Asturias,
          Canarias, Cantabria, Castilla-La Mancha y Extremadura.
        </ModTwoCols>
        <ModText>
          La herramienta te permite realizar autodiagnósticos, acceder a
          recomendaciones de actuación e información actualizada, y recibir
          recordatorios para realizar un seguimiento periódico. En ningún caso
          constituye un servicio de diagnóstico médico, de atención de urgencias
          o de prescripción de tratamientos farmacológicos.
        </ModText>
        <ModAnimation svg="/images/anim/06_abr_mapa_retorno.svg" />
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación
          trabaja para facilitar el{" "}
          <strong>retorno de unos 3.000 turistas españoles</strong> tras verse
          sorprendidos en diferentes países por la pandemia del COVID-19, y que
          se sumarían a los <strong>21.000 que ya han regresado</strong> gracias
          a las gestiones de las Embajadas y Consulados de España.
        </ModText>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, los datos registrados hasta la fecha
          muestran un incremento de 4.273 casos respecto al día anterior, así
          como 637 personas más fallecidas por coronavirus. En cuanto al número
          de personas ingresadas en UCI, la cifra asciende a 6.931, y el número
          de casos curados, a 40.437 ( que representan un 29,9% de los casos
          positivos).
        </ModText>
        <ModText>
          Estos datos refuerzan la tendencia descendente observada en los
          últimos días, tanto en el número de casos confirmados como en el
          número de casos fallecidos por COVID-19:
        </ModText>
        <ModImage src="/images/svg/img-graph-0604.svg" alt="comparativa evolución" />
        <ModText>
          Mientras que el pasado miércoles 1 de abril el número de casos
          confirmados diarios ascendía a 7.719 y el número de casos fallecios a
          864, hoy se han registrado 4.273 casos confirmados y 637 fallecidos.
        </ModText>
        <ModImage src="/images/svg/img-graph-0604-b.svg" alt="comparativa evolución" />
        <ModTwoCols
          src="/images/svg/06_abr_medidas_confinamiento.svg"
          alt="efectividad de las medidas de distanciamiento"
          small={false}
        >
          Estos cambios muestran la{" "}
          <strong>efectividad de las medidas de distanciamiento</strong>
          tomadas el pasado mes de marzo para reducir los contagios de #COVID19,
          y son indicadores claves para seguir manteniendo las indicaciones de
          prevención.
        </ModTwoCols>
        <ModImage
          src="/images/svg/06_abr_boton_SOS.svg"
          alt="AlertCops incorpora un Botón SOS para personal sanitario y mujeres víctimas de violencia de género"
        />
        <ModText>
          El Ministerio del Interior ha añadido una{" "}
          <strong>nueva funcionalidad</strong> a la aplicación móvil de
          comunicación directa con las Fuerzas y Cuerpos de Seguridad del
          Estado: AlertCops incorpora un <strong>"Botón SOS"</strong> para
          personal sanitario y mujeres víctimas de violencia de género.
        </ModText>
        <ModText>
          La nueva funcionalidad facilitará la identificación y ubicación de la
          víctima y grabará diez segundos de los hechos que estén ocurriendo
          para obtener una respuesta inmediata de las Fuerzas de Seguridad.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day24;
