import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day28 from "./dia-10-abr";
import Day27 from "./dia-09-abr";
import Day26 from "./dia-08-abr";
import Day25 from "./dia-07-abr";
import Day24 from "./dia-06-abr";
import Day23 from "./dia-05-abr";
import Day22 from "./dia-04-abr";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";

const Week = (props) => {
  return (
    <>
      <LayoutWeek {...props}>
        <SecWeekly
          period="04 Abril - 10 Abril"
          week="Semana 4 de confinamiento"
        >
          <SecWeeklyColumn>
            El Gobierno autoriza la{" "}
            <strong>segunda prórroga del estado de alarma</strong> hasta el
            próximo 26 de abril
            <ModDottedLine />
            El incremento de <strong>casos diarios</strong> disminuye{" "}
            <strong>del 40% al 8%</strong>
            <ModDottedLine />
            Se distribuyen{" "}
            <strong>un millón de test de diagnóstico rápido</strong> entre las
            CCAA
            <ModDottedLine />
            La <strong>Especificación UNE 0064-1</strong> establece los
            requisitos mínimos que deben cumplir las mascarillas higiénicas
          </SecWeeklyColumn>
          <SecWeeklyColumn>
            AlertCops incorpora un <strong> "Botón SOS"</strong> para personal
            sanitario y mujeres víctimas de violencia de género
            <ModDottedLine />
            Se habilitan <strong>trenes medicalizados</strong> para trasladar a
            enfermos de COVID-19
            <ModDottedLine />
            <strong>Hispabot-Covid19:</strong> canal de consulta sobre el
            COVID-19 a través de WhatsApp
          </SecWeeklyColumn>
        </SecWeekly>
        <Day28 {...props} />
        <ModMegaBanner>
          El Gobierno ha autorizado la segunda prórroga del
          <strong>estado de alarma</strong> hasta el próximo
          <StrongGold>26 de abril</StrongGold>
        </ModMegaBanner>
        <Day27 {...props} />
        <Day26 {...props} />
        <Day25 {...props} />
        <Day24 {...props} />
        <Day23 {...props} />
        <ModMegaBanner>
          El <strong>incremento de casos diarios</strong>
          <StrongGold> ha disminuido del 40% al 8% </StrongGold>
          desde que se empezaron a implementar las medidas de distanciamiento
          social.
        </ModMegaBanner>
        <Day22 {...props} />
      </LayoutWeek>
    </>
  );
};

export default Week;
