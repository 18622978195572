import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 26",
  week: "Semana 4",
  month: "abr",
  day: "08",
  monthNumber: "04",
  date: "2020-04-08",
  path: "/cronologia/semana-04#dia-08-abr",
};

const Day26 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModTwoCols
          src="/images/svg/08_abr_ensayo_clinico.svg"
          alt="primer ensayo clínico de covid-19"
          small={false}
        >
          En los próximos días arrancará el primer ensayo clínico para prevenir
          la enfermedad por coronavirus en profesionales sanitarios. Este Ensayo
          Clínico para la Prevención de la Infección por Coronavirus en
          Sanitarios (EPICOS) se probará en 62 hospitales de 13 comunidades
          autónomas y contará con la participación de 4.000 profesionales
          sanitarios.
        </ModTwoCols>
        <ModText>
          El objetivo es evaluar el riesgo de desarrollar la enfermedad
          sintomática por COVID-19 en personal sanitario de alto riesgo.
        </ModText>
        <ModImage
          src="/images/svg/08_abr_trenes_medicalizados.svg"
          alt="Tren Hospital"
        />
        <ModText>
          Otra de la soluciones anunciadas por el Ministerio de Transportes,
          Movilidad y Agenda Urbana, es la disponibilidad de{" "}
          <strong>
            trenes medicalizados para trasladar enfermos de COVID-19
          </strong>{" "}
          entre comunidades si fuera necesario. La capacidad de los mismos es
          para 24 pacientes, y cuentan con la peculiaridad de ser híbridos, lo
          que permite la máxima versatilidad para circular por la práctica
          totalidad de nuestra red ferroviaria.
        </ModText>
        <ModTwoCols src="/images/svg/0804-Hispabot.svg" alt="hispabot" small={false}>
          El Gobierno, por su parte, ha puesto en marcha{" "}
          <strong>Hispabot-Covid19</strong>, un canal de consulta sobre el
          COVID-19 a través de WhatsApp. Se trata de un servicio "chatbot" que
          permitirá a los ciudadanos obtener respuestas inmediatas a las
          preguntas más comunes sobre el coronavirus durante las 24 horas del
          día.
        </ModTwoCols>
        <ModText>
          Para utilizarlo, simplemente hay que guardar el número{" "}
          <strong>+34 600 802 802</strong> en los contactos del teléfono y
          escribir la palabra "hola" en un mensaje para comenzar la
          conversación.
        </ModText>
        <ModTwoCols src="/images/svg/08_abr_YoHagoPorTi.svg" alt="house" small={false}>
          En esta línea de iniciativas sociales, también se encuentra la que ha
          llevado a cabo la Dirección General de Protección Civil y Emergencias,
          en colaboración con Cruz Roja Española.{" "}
          <strong>La campaña #YoHagoPorTi</strong> tiene como objetivo impulsar
          conductas solidarias entre los vecinos, de manera que las personas más
          vulnerables, puedan contar con el apoyo de su entorno más cercano.
        </ModTwoCols>
        <ModText>
          La Comisión Europea ha presentado, además, la iniciativa{" "}
          <strong>"Equipo Europa"</strong> para coordinar los esfuerzos de
          cooperación de la Unión Europea y de los Estados Miembros para luchar
          contra la crisis.
        </ModText>
        <ModDataEu fecha={"2020-04-08"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En España, el balance del día se salda con el registro de 6.180 nuevos
          positivos y 757 personas fallecidas. Permanecen hospitalizadas 63.516
          personas, 423 más que el día anterior, y 4.813 reciben el alta. El
          número de casos ingresados en la UCI asciende a 7.132, lo que supone
          un aumento de 63 personas. Respecto al número de casos totales
          confirmados. La tasa de mortalidad es del 9,9% y la tasa de
          recuperados del 32,7%.
        </ModText>
        <ModAnimation svg="/images/anim/08_abril_spain.svg" />
        <ModText>
          A pesar de que la situación mejora, el rebote de Madrid, como
          indicador adelantado, demuestra que{" "}
          <strong>no se deben relajar</strong> las medidas de distanciamiento.
        </ModText>
        <ModGraph name="0804-confirmados-ES-MD" alt="graficas" />
        <ModText>
          El{" "}
          <strong>
            Ministerio de Sanidad sigue reforzando el Sistema Nacional de Salud
          </strong>
          : desde el pasado 10 de marzo y hasta el martes 7 de abril, se han
          repartido 64,2 millones de unidades de material entre equipos de
          protección, test de detección y dispositivos de ventilación entre las
          comunidades autónomas.
        </ModText>
        <ModImage
          src="/images/svg/08_abr_Instituto_administracion.svg"
          alt="Instituto Nacional de Administración Pública"
        />
        <ModText>
          El Instituto Nacional de Administración Pública (INAP) continúa
          trabajando en el desarrollo de los procesos selectivos, con el
          objetivo de que, una vez superada la crisis sanitaria y recuperada la
          normalidad, puedan reanudarse con el menor perjuicio para los
          aspirantes. Para ello, irá informando, con antelación suficiente, de
          todas las modificaciones y previsiones a través de su página web.
        </ModText>
        <ModText>
          En cuanto al sector agroalimentario español, el Ministerio de
          Agricultura, Pesca y Alimentación ha llegado a un acuerdo con China,
          en el que se establece la autorización a ocho nuevos establecimientos
          españoles para la exportación de carne y productos del porcino al
          mercado chino.
        </ModText>
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación
          trabaja, junto a la Comisión Europea, para dar una respuesta frente al
          COVID-19 en los países en desarrollo, y no dejar a nadie atrás.
        </ModText>
        <ModAnimation svg="/images/anim/08_abr_ministerio_deportes.svg" />
        <ModText>
          El <strong>Ministerio de Cultura y Deporte</strong> ha propuesto
          impulsar una <strong>campaña europea de sensibilización</strong> y
          educación, de respeto y reconocimiento a la cultura, ya que es uno de
          los sectores más afectados por la crisis.
        </ModText>
        <ModText>
          Asimismo, el ministro de Cultura y Deporte ha señalado que, a nivel
          europeo, es esencial reforzar los Fondos Estructurales y dotar de
          mayor financiación a Europa Creativa.
        </ModText>
        <ModTwoCols
          src="/images/svg/0804-townhall-building.svg"
          alt="medidas urgentes para el sector agroalimentario y pesquero"
          small={true}
        >
          El Ministerio de Agricultura, Pesca y Alimentación ha aprobado una
          serie de medidas urgentes para el sector agroalimentario y pesquero
          con el fin de garantizar el suministro de alimentos y apoyar a todos
          los eslabones de la cadena alimentaria.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day26;
