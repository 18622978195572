import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 28",
  week: "Semana 4",
  month: "abr",
  day: "10",
  monthNumber: "04",
  date: "2020-04-10",
  path: "/cronologia/semana-04#dia-10-abr",
};

const Day28 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModTwoCols
          src="/images/svg/1004-military-red-cross.svg"
          alt="vehículos militares sanitarios"
          small={false}
        >
          El <strong>Centro Militar de Farmacia de la Defensa</strong>,
          organismo de la red sanitaria de la Defensa de España, está
          actualmente dedicada en exclusiva a{" "}
          <strong>las necesidades derivadas de COVID-19</strong>, y ha
          incrementado su volumen para asegurar las existencias.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/1004-ia-computer.svg"
          alt="web de colaboracion"
          small={false}
          inverted={true}
        >
          A nivel autonómico, la{" "}
          <strong>Generalitat Valenciana ha habilitado una web</strong>, en
          colaboración con la Consejería de Política Territorial, Obras Públicas
          y Movilidad de Valencia, donde se podrá consultar la{" "}
          <strong>evolución de casos diarios</strong>, altas y fallecimientos de
          coronavirus, así como los datos por departamentos.
        </ModTwoCols>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-10"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.576 nuevos positivos y 683 personas fallecidas.
          Permanecen hospitalizadas 66.579 personas, 3.063 más que el día
          anterior, y 4.144 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.371, lo que supone un aumento de 239 personas.
        </ModText>
        <ModAnimation svg="/images/anim/08_abril_spain.svg"></ModAnimation>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,0 % y la tasa de recuperados del 34,2 %.
        </ModText>
        <ModImage
          src="/images/svg/1004-mascarillas-transporte.svg"
          alt="mascarillas para los trabajadores del transporte"
        ></ModImage>
        <ModText>
          Por otra parte, llegan a España más de{" "}
          <strong>un millón de mascarillas</strong>, adquiridas de forma
          centralizada por el Ministerio de Transportes, Movilidad y Agenda
          Urbana (Mitma), para{" "}
          <strong>trabajadores del transporte público</strong> terrestre
          autonómico y local, del ámbito marítimo, portuario y aéreo y de
          Correos.
        </ModText>
        <ModImage
          src="/images/svg/1004-work-center-office.svg"
          alt="Guia de buenas prácticas en el trabajo"
        ></ModImage>
        <ModText>
          El Instituto Nacional de Administración Pública (INAP) continúa
          trabajando en el desarrollo de los procesos selectivos, con el
          objetivo de que, una vez superada la crisis sanitaria y recuperada la
          normalidad, puedan reanudarse con el menor perjuicio para los
          aspirantes. Para ello, irá informando, con antelación suficiente, de
          todas las modificaciones y previsiones a través de su página web.
        </ModText>
        <ModText>
          En cuanto al sector agroalimentario español, el Ministerio de
          Agricultura, Pesca y Alimentación ha llegado a un acuerdo con China,
          en el que se establece la autorización a ocho nuevos establecimientos
          españoles para la exportación de carne y productos del porcino al
          mercado chino.
        </ModText>
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación
          trabaja, junto a la Comisión Europea, para dar una respuesta frente al
          COVID-19 en los países en desarrollo, y no dejar a nadie atrás.
        </ModText>
        <ModAnimation svg="/images/anim/08_abr_ministerio_deportes.svg"></ModAnimation>
        <ModText>
          El <strong>Ministerio de Cultura y Deporte</strong> ha propuesto
          impulsar una <strong>campaña europea de sensibilización</strong> y
          educación, de respeto y reconocimiento a la cultura, ya que es uno de
          los sectores más afectados por la crisis.
        </ModText>
        <ModText>
          Asimismo, el ministro de Cultura y Deporte ha señalado que, a nivel
          europeo, es esencial reforzar los Fondos Estructurales y dotar de
          mayor financiación a Europa Creativa.
        </ModText>
        <ModTwoCols
          src="/images/svg/1004-grupo-de-trabajo.svg"
          alt="mesa de dialogo social"
          small={true}
        >
          El Ministerio de Trabajo ha celebrado una{" "}
          <strong>mesa de Diálogo Social</strong> para abordar la situación de
          trabajadores y empresas tras la crisis sanitaria. El objetivo ha sido
          avanzar en los mecanismos y medidas que se necesitarán en el proceso
          de recuperación y tratar la situación de los sectores más afectados
          por las consecuencias laborales de la pandemia.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/1004-government-digital-money.svg"
          alt="avales-gubernamentales"
          small={false}
        >
          Asimismo, el Gobierno ha activado el segundo tramo de la Línea de
          Avales, con 20.000 millones de euros destinados íntegramente a pymes y
          autónomos, por considerar que son los agentes de la actividad
          económica que más requieren de este apoyo en la actualidad.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day28;
