import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 23",
  week: "Semana 4",
  month: "abr",
  day: "05",
  monthNumber: "04",
  date: "2020-04-05",
  path: "/cronologia/semana-04#dia-05-abr",
};

const Day23 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-05"} />
        <ModText>
          En cuanto a los datos recogidos sobre movilidad y transporte, podemos
          decir que desde que comenzó el período de confinamiento, el Ministerio
          de Transportes, Movilidad y Agenda Urbana ha puesto en marcha 84
          acciones para reducir la movilidad, garantizar el abastecimiento y
          proteger a los más vulnerables.
        </ModText>
        <ModText>
          Entre ellas, 35 corresponden al transporte de viajeros, 36 al de
          mercancías y profesionales del sector, 9 relacionadas con su impacto
          económico y 4 de índole social, y a las que hay que añadir las
          relacionadas con las cuestiones organizativas internas del
          Departamento.
        </ModText>
        <ModText>
          <strong>Estas medidas, han llevado consigo una reducción del:</strong>
        </ModText>
        <ModImage
          src="/images/svg/05_abr_reduccion.svg"
          alt="84 acciones para reducir la movilidad, garantizar el abastecimiento y proteger a los más vulnerables"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 130.759
          casos (6.023 más que el día anterior), 12.418 fallecidos (9,5% de los
          casos positivos) y 38.080 curados (29,1% de los casos positivos). El
          total de personas en UCI asciende a 6.861.
        </ModText>
        <ModTwoCols
          src="/images/svg/05_abr_distribuir_test.svg"
          alt="un millón de test de diagnóstico rápido entre las Comunidades Autónomas"
          small={true}
        >
          El Ministerio de Sanidad ha empezado a distribuir{" "}
          <strong>
            un millón de test de diagnóstico rápido entre las Comunidades
            Autónomas
          </strong>
          , con el objetivo de realizar el máximo número de test posible a las
          personas que tengan síntomas.
        </ModTwoCols>
        <ModText>
          Una vez que el Instituto de Salud Carlos III (ISCIII) ha evaluado más
          de 70 modelos de test rápidos, se ha elegido el que cuenta con mayor
          sensibilidad:
        </ModText>
        <ModImage
          src="/images/svg/img-sensibility.svg"
          alt="estrategia de diagnóstico test rapido mayores"
        />
        <ModText>
          La pieza clave de la estrategia de diagnóstico continuará siendo el
          test de PCR, y el test rápido será un complemento para llegar a un
          mayor número de población diagnosticada. Irá dirigido especialmente a
          hospitales y colectivos vulnerables, como las residencias de mayores.
        </ModText>
        <ModTwoCols
          src="/images/svg/05_abr_obra_social.svg"
          alt="asistencia psicológica a profesionales sanitario"
          small={true}
        >
          Por otra parte, Fundación La Caixa, junto con la colaboración del
          Ministerio de Sanidad, se suma a la lista de entidades que están
          aportando su granito de arena a la situación, ofreciendo{" "}
          <strong>asistencia psicológica a profesionales sanitarios</strong>
          implicados en la lucha contra COVID-19: atención telefónica,
          videoconferencia y seguimiento on-line de 9 a 22 horas, todos los
          días.
        </ModTwoCols>
        <ModImage
          src="/images/svg/05_abr_obra_social_todo_el_ancho.svg"
          alt="asistencia psicológica a profesionales sanitario"
        />
        <ModText>
          Entre las miles de aportaciones solidarias que se están llevando a
          cabo a nivel autonómico, cabe destacar las ayudas que está recibiendo
          la Comunidad Madrid, región con el mayor número de casos notificados.
        </ModText>
        <ModImage
          src="/images/svg/05_abr_ayuda_comunidad_madrid.svg"
          alt="Ofrecimiento de ayuda voluntaria a la Cominidad de Madrid"
        />
        <ModText>
          Y por último, en materia económica, comienza a estar operativa{" "}
          <strong>
            la Línea de Avales del Ministerio de Asuntos Económicos y
            Transformación Digital
          </strong>
          , destinada a facilitar la liquidez de autónomos, pymes y empresas.
          Gracias a los convenios de colaboración con el ICO y las entidades
          financieras, podrán cubrirse las operaciones concedidas desde el 18 de
          marzo.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day23;
